import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { QRCodeCanvas } from "qrcode.react";

import { apiUrl } from "../../config";
class SeloNacional extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      data: {},
    };
  }

  componentDidMount() {
    fetch(`${apiUrl}/${this.props.token}/selo-nacional`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({ data, Loader: false });
          });
        }
      })
      .catch((error) => {
        toast.warn("Erro ao carregar selo!");
      });
  }

  render() {
    if (this.state.Loader) {
      return <>Carregando selo...</>;
    }
    var data = this.state.data;
    return (
      <>
        <div className="card">
          <div class="card-header d-flex justify-content-between">
            <h3>Selo Nacional:</h3>
            <button
              class="btn  btn-primary"
              disabled={Object.keys(data).length > 0 ? true : false}
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/criar-selo-nacional`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    sigla: this.props.sigla,
                    token: this.props.token,
                    link: this.props.link,
                  }),
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      response.json().then((selo) => {
                        this.setState({
                          data: { ...data, selo_n: selo.selo_n },
                        });
                      });
                      toast.success("Selo criado com sucesso!");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faFile} /> Criar Selo
            </button>
          </div>
          <div className="card-body">
            {Object.keys(data).length > 0 ? (
              <div className="d-flex  align-items-center">
                <div>
                  {" "}
                  <QRCodeCanvas
                    id="selo_nacional"
                    value={data.selo_n}
                    size={164}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    includeMargin={true}
                  />
                  <div className="mt-2 text-center">{data.selo_n}</div>
                </div>
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn  btn-primary"
                  onClick={() => {
                    const canvas = document.getElementById("selo_nacional");

                    const imageData = canvas.toDataURL("image/png");

                    const img = document.createElement("img");
                    img.src = "data:image/png;base64," + imageData;
                    document.body.appendChild(img);
                    const range = document.createRange();
                    range.selectNode(img);
                    const sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                    document.execCommand("copy");
                    document.body.removeChild(img);

                    toast.success(
                      "Imagem copiada para a área de transferência!"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} /> Copiar
                </button>

                <button
                  className="btn  btn-primary"
                  onClick={(e) => {
                    window.open(
                      `https://certidao.registrocivilbr.org/validar/${data.selo_n}`,
                      "_blank"
                    );
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <FontAwesomeIcon color="#fff" icon={faLink} /> Acessar
                </button>
              </div>
            ) : (
              <span>O selo ainda não foi criado</span>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default SeloNacional;
