import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

import logo from "../../../images/logoTjsp.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../../config";
import lg1 from "../../../images/funarpen.jpg";
import lg2 from "../../../images/logotj.png";
import dcmt from "../../../images/document.png";
import SeloNacional from "../../../Comps/SeloNacional";
class PreViewCertRjNt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(
      `${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      } else if (response.status == 404) {
        this.setState({ error: true, Loader: false });
      }
    });
  }

  formatToBRL(value) {
    // Certifique-se de que o valor é um número
    if (value == "") {
      return "";
    }

    // Converta o número para o formato de moeda BRL
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.error == true) {
      return (
        <>
          <div class="p404">
            <div>
              <img src={dcmt} />
              <p>Desculpe, Esse documento não foi encontrado.</p>
              <button
                type="submit"
                class="btn btn-primary float-left ml-2"
                onClick={() => {
                  this.props.navigate(-1);
                }}
              >
                {" "}
                <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Voltar
              </button>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Pré-visualização do
              certificado{" "}
            </h2>
          </div>
        </header>

        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div class="card-header d-flex justify-content-between">
                      <h3>Qrcode:</h3>
                      <a href={this.state.data.link} target="_blank">
                        <button class="btn btn-xs btn-primary">
                          <FontAwesomeIcon color="#fff" icon={faLink} /> Acessar
                        </button>
                      </a>
                    </div>
                    <div className="card-body">
                      <div className="flex items-center">
                        <img
                          src={this.state.data.QrCode}
                          style={{ width: "200px", height: "200px" }}
                          width="200"
                          height="200"
                        />
                        <button
                          class="btn btn-primary"
                          onClick={() => {
                            const img = document.createElement("img");
                            img.src = this.state.data.QrCode;
                            document.body.appendChild(img);
                            const range = document.createRange();
                            range.selectNode(img);
                            const sel = window.getSelection();
                            sel.removeAllRanges();
                            sel.addRange(range);
                            document.execCommand("copy");
                            document.body.removeChild(img);

                            toast.success(
                              "Imagem copiada para a área de transferência!"
                            );
                          }}
                        >
                          Copiar QrCode
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SeloNacional token={window.location.pathname.split("/")[3]} />
              <div className="col-12 mt-3">
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>Dados da certidão:</h3>
                    <Link
                      to={`/${window.location.pathname.split("/")[1]}/edit/${
                        this.state.data.token
                      }`}
                    >
                      <button class="btn btn-xs btn-primary">
                        <FontAwesomeIcon color="#fff" icon={faPen} /> Editar
                      </button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="justify-content-center">
                      {/* <h2>Dados do Serviço Extrajudicial</h2>
          <table className="gridtable" width="100%">
            <tbody>
              <tr>
                <td width="15%">Código</td>
                <td width="85%"><b>1337</b></td>
              </tr>
              <tr>
                <td>Nome</td>
                <td><b>RIO BONITO RCPN 01 DISTR</b></td>
              </tr>
              <tr>
                <td>Endereço</td>
                <td><b>Rua Desembargador Itabaiana de Oliveira - 76 - Sala 07</b></td>
              </tr>
              <tr>
                <td>CEP</td>
                <td><b>28800-000</b></td>
              </tr>
              <tr>
                <td>Bairro</td>
                <td><b>RIO BONITO (TODOS OS SETORES)</b></td>
              </tr>
              <tr>
                <td>Município</td>
                <td><b>RIO BONITO</b></td>
              </tr>
              <tr>
                <td>Comarca</td>
                <td><b>Comarca de Rio Bonito</b></td>
              </tr>
              <tr>
                <td>Telefones</td>
                <td><b>(21) 2734-4012</b>
                  <p><b></b></p>
                </td>
              </tr>
              <tr>
                <td>Observação</td>
                <td><b>
                    <font color="##FF0000" size="2"></font>
                  </b></td>
              </tr>
            </tbody>
          </table> */}
                      <div className="col-xs-12">
                        <br />
                        <h2>Dados do Serviço Extrajudicial</h2>
                        <div id="divServicoExtrajudicial">
                          <table
                            className="gridtable table-bordered"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td width="15%">Código</td>
                                <td width="85%">
                                  <b>{this.state.data.cartorio.cds}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Nome</td>
                                <td>
                                  <b>{this.state.data.cartorio.nome}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Endereço</td>
                                <td>
                                  <b>{this.state.data.cartorio.edrc}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>CEP</td>
                                <td>
                                  <b>
                                    {this.state.data.cartorio.cep.replace(
                                      /^(\d{2})(\d{3})(\d{3})$/,
                                      "$1$2-$3"
                                    )}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Bairro</td>
                                <td>
                                  <b>{this.state.data.cartorio.bairro}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Município</td>
                                <td>
                                  <b>{this.state.data.cartorio.municipio}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Comarca</td>
                                <td>
                                  <b>{this.state.data.cartorio.cma}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Telefones</td>
                                <td>
                                  <b>{this.state.data.cartorio.telefone}</b>
                                  <p>
                                    <b></b>
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td>Observação</td>
                                <td>
                                  <b>
                                    <font color="##FF0000" size="2">
                                      {this.state.data.cartorio.obs}
                                    </font>
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <br />
                        <div id="divAto">
                          <h2>Dados do Ato Extrajudicial</h2>
                          <table className="gridtable" width="100%">
                            <tbody>
                              <tr>
                                <td width="15%">Selo</td>
                                <td width="95%">
                                  <b>{this.state.data.token.split("-")[0]}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Código Aleatório</td>
                                <td>
                                  <b>{this.state.data.token.split("-")[1]}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Tipo de Ato</td>
                                <td>
                                  <b>{this.state.data.tipo_ato}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Tipo de Certidão</td>
                                <td>
                                  <b>
                                    <font size="2"></font>
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Data da Prática</td>
                                <td>
                                  <b>
                                    {this.state.data.data_pratica
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Tipo de Cobrança</td>
                                <td>
                                  <b>{this.state.data.tipo_cobranca}</b>
                                </td>
                              </tr>

                              <tr>
                                <td>Nº Livro</td>
                                <td>
                                  <b>{this.state.data.numero_livro}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Nº Folha</td>
                                <td>
                                  <b>{this.state.data.numero_folha}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Nº Termo</td>
                                <td>
                                  <b>{this.state.data.numero_termo}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Matricula Nacional CNJ</td>
                                <td>
                                  <b>{this.state.data.cnj}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Tipo do Livro CNJ</td>
                                <td>
                                  <b>{this.state.data.tipo_livro_cnj}</b>
                                </td>
                              </tr>

                              <tr>
                                <td>{this.state.data.adc1}</td>
                                <td>
                                  <b>
                                    {this.state.data.adc2
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  </b>
                                </td>
                              </tr>

                              {this.state.data.observacoes != "" && (
                                <>
                                  <tr>
                                    <td>Observações</td>
                                    <td>
                                      <b>{this.state.data.observacoes}</b>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div id="divParticipantes">
                          <h2>Participantes</h2>
                          <table
                            className="gridtable"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <th>Nome</th>
                                <th>Nomeclatura</th>
                                <th>Data Nascimento</th>
                                <th>CPF / CNPJ</th>
                                <th>Identidade</th>
                                <th>Orgão Emissor</th>
                                <th>Estado Civil</th>
                                <th>Nacionalidade</th>
                                <th>Just. CPF/CNPJ</th>
                              </tr>
                              {this.state.data.participantes.map((item) => (
                                <>
                                  <tr>
                                    <td>{item.nomeP}</td>
                                    <td>{item.nmcP}</td>
                                    <td>
                                      {item.dtnacP != null &&
                                        item.dtnacP
                                          .split("-")
                                          .reverse()
                                          .join("/")}
                                    </td>
                                    <td>{item.docP}</td>
                                    <td>{item.idtP}</td>
                                    <td>{item.orgeP}</td>
                                    <td>{item.estcP}</td>
                                    <td>{item.ncldP}</td>
                                    <td>{item.jdocP}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                          <br />
                          <div id="black_overlay">
                            <div id="pop">
                              <p style={{ textAlign: "right" }}>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    (document.getElementById(
                                      "black_overlay"
                                    ).style.display = "none")
                                  }
                                >
                                  <b>[Fechar]</b>
                                </a>
                              </p>
                              <h2 align="center">Participantes</h2>
                              <table className="gridtable">
                                <tbody>
                                  <tr>
                                    <td>Nome</td>
                                    <td width="95%">
                                      <b>VALMIR BARROS LOPES DA SILVA</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nomeclatura</td>
                                    <td width="95%">
                                      <b>230 - NASCIDO</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Data Nascimento</td>
                                    <td>
                                      <b>11/11/1953</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>CPF</td>
                                    <td>
                                      <b>45270872749</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Município de naturalidade</td>
                                    <td>
                                      <b>RIO BONITO</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <br />
                              <table className="gridtable">
                                <tbody>
                                  <tr>
                                    <td>Nome</td>
                                    <td width="95%">
                                      <b>LUIZ LOPES DA SILVA</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nomeclatura</td>
                                    <td width="95%">
                                      <b>231 - PAI</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nome</td>
                                    <td width="95%">
                                      <b>NAIR BARROS</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nomeclatura</td>
                                    <td width="95%">
                                      <b>232 - MÃE</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div id="divEmolumentos">
                            <h2>Emolumentos</h2>
                            <table class="gridtable" width="200">
                              <tbody>
                                <tr>
                                  <td width="100">Emolumentos</td>
                                  <td align="right" width="100">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.Emolumentos)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>FETJ</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.FETJ)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>FUNDPERJ</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.FUNDPERJ)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>FUNPERJ</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.FUNPERJ)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>FUNARPEN</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.FUNARPEN)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>RESSAG</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.RESSAG)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Valor Mútua</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.Valor_Mutua)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Valor Acoterj</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.Valor_Acoterj)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Valor Distribuidor</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(
                                          this.state.data.Valor_Distribuidor
                                        )
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Valor do Selo Eletrônico</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(
                                          this.state.data
                                            .Valor_do_Selo_Eletronico
                                        )
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Valor ISS</td>
                                  <td align="right">
                                    <b>
                                      {this.formatToBRL(
                                        Number(this.state.data.Valor_ISS)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br />
                            <br />
                            <div id="divHistoricoRetificacoes">
                              <h2>Histórico de Transmissões/Retificações</h2>
                              <table class="gridtable">
                                <tbody>
                                  <tr>
                                    <th>Status</th>
                                    <th>Selo</th>
                                    <th>Aleatório</th>
                                    <th>Data da Prática</th>
                                    <th>Tipo de Ato</th>
                                    <th>Tipo de Cobrança</th>
                                    <th>Data de Transmissão</th>
                                  </tr>
                                  <tr>
                                    <td>Transmitido</td>
                                    <td>
                                      {this.state.data.token.split("-")[0]}
                                    </td>
                                    <td>
                                      {this.state.data.token.split("-")[1]}
                                    </td>
                                    <td>
                                      {this.state.data.data_pratica
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                    </td>
                                    <td>{this.state.data.tipo_ato}</td>
                                    <td>{this.state.data.tipo_cobranca}</td>
                                    <td>{this.state.data.dtmc}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      class="btn btn-danger"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToastContainer />
      </>
    );
  }
}

export default PreViewCertRjNt;
