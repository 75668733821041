import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import "./s.css";
import logo from "../../../images/logoTjsp.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../../config";
import lg1 from "../../../images/funarpen.jpg";
import lg2 from "../../../images/logotj.png";
import dcmt from "../../../images/document.png";
import SeloNacional from "../../../Comps/SeloNacional";
class PreViewCertMg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do certificado";
    var url = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(
      `${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data });

          if (data.subs.length > 0) {
            var t1 = 0;
            var t2 = 0;
            data.subs.forEach((item) => {
              t1 += this.parseCurrency(item.v1);
              t2 += this.parseCurrency(item.v2);
            });
            this.setState({
              valores: [t1, t2, t1 + t2],
              Loader: false,
            });
          } else {
            this.setState({
              valores: [0, 0, 0],
              Loader: false,
            });
          }
        });
      } else if (response.status == 404) {
        this.setState({ error: true, Loader: false });
      }
    });
  }

  Show(value) {
    var message = "";
    switch (value) {
      case "701":
        message = "A - Nascimento";
        break;
      case "702":
        message = "B - Casamento";
        break;
      case "703":
        message = "B-Aux Casamento religioso";
        break;
      case "704":
        message = "C - Óbito";
        break;
      case "705":
        message = "C-Aux - Natimorto";
        break;
      case "706":
        message = "D - Proclamas";
        break;
      case "707":
        message = "E - Inscrição Registro Civil";
        break;
      default:
        message = "Selecione uma opção válida";
    }

    return message;
  }

  parseCurrency(value) {
    return parseFloat(value.replace("R$", "").replace(",", "."));
  }

  render() {
    var body = this.state.data;

    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }

    if (this.state.error == true) {
      return (
        <>
          <div class="p404">
            <div>
              <img src={dcmt} />
              <p>Desculpe, Esse documento não foi encontrado.</p>
              <button
                type="submit"
                class="btn btn-primary float-left ml-2"
                onClick={() => {
                  this.props.navigate(
                    `/${window.location.pathname.split("/")[1]}`
                  );
                }}
              >
                {" "}
                <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
              </button>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Pré-visualização do
              certificado{" "}
            </h2>
          </div>
        </header>

        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div class="card-header d-flex justify-content-between">
                      <h3>Qrcode:</h3>
                      <a href={this.state.data.link} target="_blank">
                        <button class="btn btn-xs btn-primary">
                          <FontAwesomeIcon color="#fff" icon={faLink} /> Acessar
                        </button>
                      </a>
                    </div>
                    <div className="card-body">
                      <div className="flex items-center">
                        <img
                          src={this.state.data.QrCode}
                          style={{ width: "200px", height: "200px" }}
                          width="200"
                          height="200"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SeloNacional token={window.location.pathname.split("/")[3]} />
              <div className="col-12 mt-3">
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>Dados da certidão:</h3>
                    <Link
                      to={`/${window.location.pathname.split("/")[1]}/edit/${
                        this.state.data.token
                      }`}
                    >
                      <button class="btn btn-xs btn-primary">
                        <FontAwesomeIcon color="#fff" icon={faPen} /> Editar
                      </button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <div
                      id="detalhamentoAtoPraticado:j_idt77"
                      className="ui-panel ui-widget ui-widget-content ui-corner-all"
                      data-widget="widget_detalhamentoAtoPraticado_j_idt77"
                    >
                      <div
                        id="detalhamentoAtoPraticado:j_idt77_content"
                        className="ui-panel-content ui-widget-content"
                      >
                        <table cellSpacing="5">
                          <tbody>
                            <tr>
                              <td>
                                <label
                                  id="detalhamentoAtoPraticado:j_idt79"
                                  className="ui-outputlabel ui-widget"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#222222",
                                  }}
                                >
                                  Selo de Fiscalização Eletrônico consultado:
                                </label>
                              </td>
                              <td>
                                <label
                                  id="detalhamentoAtoPraticado:j_idt80"
                                  className="ui-outputlabel ui-widget"
                                  style={{ color: "#222222" }}
                                >
                                  {body.cert.token}
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellSpacing="5">
                          <tbody>
                            <tr>
                              <td>
                                <table cellSpacing="0">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <label
                                          id="detalhamentoAtoPraticado:j_idt83"
                                          className="ui-outputlabel ui-widget"
                                          style={{
                                            fontWeight: "bold",
                                            color: "#222222",
                                          }}
                                        >
                                          Detalhamento do
                                        </label>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label
                                          id="detalhamentoAtoPraticado:j_idt84"
                                          className="ui-outputlabel ui-widget"
                                          style={{
                                            fontWeight: "bold",
                                            color: "#222222",
                                          }}
                                        >
                                          grupo de selos
                                        </label>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table cellSpacing="5">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt87"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  Selo de consulta do grupo:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt88"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  {this.state.data.token}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt90"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  Total de selos:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt91"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  {1 + body.subs.length}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt93"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  Selos válidos:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt94"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  {1 + body.subs.length}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt96"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  Selos cancelados:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt97"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  0
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#222222",
                                  }}
                                >
                                  Total de taxas
                                </span>
                              </td>
                              <td>
                                <table cellSpacing="5">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt101"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  Emolumentos:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt102"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  {(
                                                    Number(
                                                      body.cert.valor_emolumento
                                                    ) + this.state.valores[0]
                                                  ).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  })}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt104"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  TFJ:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt105"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  {(
                                                    Number(
                                                      body.cert
                                                        .valor_taxa_fiscalizacao_judiciaria
                                                    ) + this.state.valores[1]
                                                  ).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  })}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          cellSpacing="1"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt107"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "dimgray" }}
                                                >
                                                  Valor final ao usuário:
                                                </label>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <label
                                                  id="detalhamentoAtoPraticado:j_idt108"
                                                  className="ui-outputlabel ui-widget"
                                                  style={{ color: "#222222" }}
                                                >
                                                  {(
                                                    Number(
                                                      body.cert
                                                        .valor_final_usuario
                                                    ) +
                                                    this.state.valores[0] +
                                                    this.state.valores[1]
                                                  ).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  })}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <table id="detalhamentoAtoPraticado:tabelaDetalhamento">
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120"
                                  className="ui-datatable ui-widget"
                                >
                                  <div className="ui-datatable-header ui-widget-header ui-corner-top">
                                    <a id="seloEXU60212"></a>Selo{" "}
                                    {body.cert.token}
                                    <br />8 - Certidões: 8.1 - Certidão de
                                    livros: 8.1.2 - De inteiro teor
                                  </div>
                                  <div className="ui-datatable-tablewrapper">
                                    <table role="grid">
                                      <thead id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120_head">
                                        <tr role="row">
                                          <th
                                            id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120:j_idt125"
                                            className="ui-state-default"
                                            role="columnheader"
                                            aria-label="Campo"
                                            scope="col"
                                          >
                                            <span className="ui-column-title">
                                              Campo
                                            </span>
                                          </th>
                                          <th
                                            id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120:j_idt127"
                                            className="ui-state-default"
                                            role="columnheader"
                                            aria-label="Valor"
                                            scope="col"
                                          >
                                            <span className="ui-column-title">
                                              Valor
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120_data"
                                        className="ui-datatable-data ui-widget-content"
                                      >
                                        <tr
                                          data-ri="0"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Tabela de referência
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.referencia}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="1"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Código fiscal do ato
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.codigo_fiscal_ato}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="2"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Código de segurança
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.codigo_seguranca}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="3"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Serventia
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.nome}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="4"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Data da prática do Ato
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {new Date(
                                              body.cert.data_pratica_ato
                                            ).toLocaleDateString("pt-BR")}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="5"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Data/Hora da utilização do Selo
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {new Date(
                                              body.cert.data_hora_utilizacao_selo
                                            )
                                              .toLocaleString("pt-BR", {
                                                dateStyle: "short",
                                                timeStyle: "short",
                                              })
                                              .replace(",", "")}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="6"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Data/Hora da transmissão do Ato
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {new Date(
                                              body.cert.data_hora_transmissao_ato
                                            )
                                              .toLocaleString("pt-BR", {
                                                dateStyle: "short",
                                                timeStyle: "short",
                                              })
                                              .replace(",", "")}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="7"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Tipo de Tributação
                                            </span>
                                          </td>
                                          <td role="gridcell">Normal</td>
                                        </tr>
                                        <tr
                                          data-ri="8"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Valor do Emolumento
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {Number(
                                              body.cert.valor_emolumento
                                            ).toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            })}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="9"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Valor da Taxa de Fiscalização
                                              Judiciária
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {Number(
                                              body.cert
                                                .valor_taxa_fiscalizacao_judiciaria
                                            ).toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            })}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="10"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Valor Final ao Usuário
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {Number(
                                              body.cert.valor_final_usuario
                                            ).toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            })}
                                          </td>
                                        </tr>
                                        <tr
                                          data-ri="11"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Protocolo
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.protocolo}
                                          </td>
                                        </tr>

                                        <tr
                                          data-ri="18"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Detalhamento da Certidão
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.detalhamento_certidao}
                                          </td>
                                        </tr>

                                        <tr
                                          data-ri="19"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Nome da Pessoa
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.nome_pessoa}
                                          </td>
                                        </tr>

                                        <tr
                                          data-ri="19"
                                          className="ui-widget-content ui-datatable-odd"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              CPF Pessoa
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.cpf}
                                          </td>
                                        </tr>

                                        <tr
                                          data-ri="20"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Complemento da Certidão
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.complemento_certidao}
                                          </td>
                                        </tr>

                                        <tr
                                          data-ri="20"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Nome do responsavél pela prática
                                              do ato:
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.resp}
                                          </td>
                                        </tr>

                                        <tr
                                          data-ri="20"
                                          className="ui-widget-content ui-datatable-even"
                                          role="row"
                                        >
                                          <td role="gridcell">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              Qualificação do responsavél pela
                                              prática do ato:
                                            </span>
                                          </td>
                                          <td role="gridcell">
                                            {body.cert.quali}
                                          </td>
                                        </tr>

                                        {this.state.data.cert.reg_bens !=
                                          null && (
                                          <>
                                            <tr
                                              data-ri="20"
                                              className="ui-widget-content ui-datatable-even"
                                              role="row"
                                            >
                                              <td role="gridcell">
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    float: "right",
                                                  }}
                                                >
                                                  Regime de bens
                                                </span>
                                              </td>
                                              <td role="gridcell">
                                                {this.state.data.cert.reg_bens}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            {this.state.data.subs.map((item, index) => (
                              <tr>
                                <td>
                                  <div
                                    id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120"
                                    className="ui-datatable ui-widget"
                                  >
                                    <div className="ui-datatable-header ui-widget-header ui-corner-top">
                                      <a id="seloEXU60212"></a>Selo{" "}
                                      {body.cert.token.substr(0, 3)}
                                      {Number(body.cert.token.substr(3)) +
                                        1 +
                                        index}
                                      <br />
                                      {item.ato}
                                    </div>
                                    <div className="ui-datatable-tablewrapper">
                                      <table role="grid">
                                        <thead id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120_head">
                                          <tr role="row">
                                            <th
                                              id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120:j_idt125"
                                              className="ui-state-default"
                                              role="columnheader"
                                              aria-label="Campo"
                                              scope="col"
                                            >
                                              <span className="ui-column-title">
                                                Campo
                                              </span>
                                            </th>
                                            <th
                                              id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120:j_idt127"
                                              className="ui-state-default"
                                              role="columnheader"
                                              aria-label="Valor"
                                              scope="col"
                                            >
                                              <span className="ui-column-title">
                                                Valor
                                              </span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          id="detalhamentoAtoPraticado:tabelaDetalhamento:0:j_idt120_data"
                                          className="ui-datatable-data ui-widget-content"
                                        >
                                          <tr
                                            data-ri="0"
                                            className="ui-widget-content ui-datatable-even"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Tabela de referência
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {body.cert.referencia}
                                            </td>
                                          </tr>
                                          <tr
                                            data-ri="1"
                                            className="ui-widget-content ui-datatable-odd"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Código fiscal do ato
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {item.code_fisc}
                                            </td>
                                          </tr>
                                          <tr
                                            data-ri="2"
                                            className="ui-widget-content ui-datatable-even"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Código de segurança
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {item.codeser}
                                            </td>
                                          </tr>
                                          <tr
                                            data-ri="3"
                                            className="ui-widget-content ui-datatable-odd"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Serventia
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {body.cert.nome}
                                            </td>
                                          </tr>
                                          <tr
                                            data-ri="4"
                                            className="ui-widget-content ui-datatable-even"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Data da prática do Ato
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {new Date(
                                                body.cert.data_pratica_ato
                                              ).toLocaleDateString("pt-BR")}
                                            </td>
                                          </tr>
                                          <tr
                                            data-ri="5"
                                            className="ui-widget-content ui-datatable-odd"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Data/Hora da utilização do Selo
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {new Date(
                                                body.cert.data_hora_utilizacao_selo
                                              )
                                                .toLocaleString("pt-BR", {
                                                  dateStyle: "short",
                                                  timeStyle: "short",
                                                })
                                                .replace(",", "")}
                                            </td>
                                          </tr>

                                          <tr
                                            data-ri="6"
                                            className="ui-widget-content ui-datatable-even"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Data/Hora da transmissão do Ato
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {new Date(
                                                body.cert.data_hora_transmissao_ato
                                              )
                                                .toLocaleString("pt-BR", {
                                                  dateStyle: "short",
                                                  timeStyle: "short",
                                                })
                                                .replace(",", "")}
                                            </td>
                                          </tr>
                                          <tr
                                            data-ri="7"
                                            className="ui-widget-content ui-datatable-odd"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Tipo de Tributação
                                              </span>
                                            </td>
                                            <td role="gridcell">Normal</td>
                                          </tr>
                                          <tr
                                            data-ri="8"
                                            className="ui-widget-content ui-datatable-even"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Valor do Emolumento
                                              </span>
                                            </td>
                                            <td role="gridcell">{item.v1}</td>
                                          </tr>
                                          <tr
                                            data-ri="9"
                                            className="ui-widget-content ui-datatable-odd"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Valor da Taxa de Fiscalização
                                                Judiciária
                                              </span>
                                            </td>
                                            <td role="gridcell">{item.v2}</td>
                                          </tr>
                                          <tr
                                            data-ri="10"
                                            className="ui-widget-content ui-datatable-even"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Valor Final ao Usuário
                                              </span>
                                            </td>
                                            <td role="gridcell">{item.v3}</td>
                                          </tr>
                                          <tr
                                            data-ri="11"
                                            className="ui-widget-content ui-datatable-odd"
                                            role="row"
                                          >
                                            <td role="gridcell">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "right",
                                                }}
                                              >
                                                Protocolo
                                              </span>
                                            </td>
                                            <td role="gridcell">
                                              {item.protocolo}
                                            </td>
                                          </tr>
                                          {item.type == "1" && (
                                            <>
                                              <tr
                                                data-ri="11"
                                                className="ui-widget-content ui-datatable-odd"
                                                role="row"
                                              >
                                                <td role="gridcell">
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      float: "right",
                                                    }}
                                                  >
                                                    Tipo de livro
                                                  </span>
                                                </td>
                                                <td role="gridcell">
                                                  {this.Show(
                                                    item.tipo_de_livro
                                                  )}
                                                </td>
                                              </tr>

                                              <tr
                                                data-ri="11"
                                                className="ui-widget-content ui-datatable-odd"
                                                role="row"
                                              >
                                                <td role="gridcell">
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      float: "right",
                                                    }}
                                                  >
                                                    Ordem sequencial do livro
                                                    (L), matrícula (M) ou ficha
                                                    (F)
                                                  </span>
                                                </td>
                                                <td role="gridcell">
                                                  {item.orderm_s}
                                                </td>
                                              </tr>

                                              <tr
                                                data-ri="11"
                                                className="ui-widget-content ui-datatable-odd"
                                                role="row"
                                              >
                                                <td role="gridcell">
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      float: "right",
                                                    }}
                                                  >
                                                    Folha
                                                  </span>
                                                </td>
                                                <td role="gridcell">
                                                  {item.folha}
                                                </td>
                                              </tr>

                                              <tr
                                                data-ri="11"
                                                className="ui-widget-content ui-datatable-odd"
                                                role="row"
                                              >
                                                <td role="gridcell">
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      float: "right",
                                                    }}
                                                  >
                                                    Tipo de Escrituração
                                                  </span>
                                                </td>
                                                <td role="gridcell">
                                                  Registro
                                                </td>
                                              </tr>

                                              <tr
                                                data-ri="11"
                                                className="ui-widget-content ui-datatable-odd"
                                                role="row"
                                              >
                                                <td role="gridcell">
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      float: "right",
                                                    }}
                                                  >
                                                    Número de Ordem
                                                  </span>
                                                </td>
                                                <td role="gridcell">
                                                  {item.nmr_dor}
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      class="btn btn-danger"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToastContainer />
      </>
    );
  }
}

export default PreViewCertMg;
