import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import { StaticDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";

class TjSc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      addCartorio: {},
      editCartorio: {},
      dados: [],
      newSlc: false,
      DelSlc: {},
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "Santa Catarina - Consulta";
    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEditChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      editCartorio: {
        ...prevState.editCartorio, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class=" shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjSc{" "}
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <button
                      class="btn btn-xs btn-primary-2"
                      style={{ background: "#dc3545", color: "#fff" }}
                      onClick={() => {
                        this.setState({ addCartorio: { nomeOfc: "" } });
                        return;
                        Modal.create({
                          titulo: "Adicionar cartório",
                          html: (
                            <>
                              <div class="card-body">
                                <div class="form-group mt-2">
                                  <label>Nome do Ofício:</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    onChange={(e) => {
                                      this.setState({
                                        nomeOfc: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div class="form-group mt-2">
                                  <label>Responsável:</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    onChange={(e) => {
                                      this.setState({ Resp: e.target.value });
                                    }}
                                  />
                                </div>
                                <div class="form-group mt-2">
                                  <label>Endereço:</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    onChange={(e) => {
                                      this.setState({ Edrc: e.target.value });
                                    }}
                                  />
                                </div>
                                <div class="form-group mt-2">
                                  <label>Cidade/UF:</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    onChange={(e) => {
                                      this.setState({ Cidade: e.target.value });
                                    }}
                                  />
                                </div>
                                <div class="form-group mt-2">
                                  <label>Telefone:</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    onChange={(e) => {
                                      this.setState({
                                        Telefone: e.target.value,
                                      });
                                    }}
                                  />

                                  <div class="form-group mt-2">
                                    <label>Cns:</label>
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      onChange={(e) => {
                                        this.setState({ Cns: e.target.value });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="card-footer">
                                <button
                                  type="submit"
                                  style={{ background: "#0d6efd" }}
                                  class="btn btn-primary float-right ml-2"
                                  onClick={() => {
                                    const id = toast.loading("Enviando...");

                                    fetch(
                                      `${apiUrl}${window.location.pathname}/adicionar-cartorio/`,
                                      {
                                        method: "POST",
                                        credentials: "include",
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization:
                                            "Bearer " +
                                            Cookies.get("auth_token"),
                                        },
                                        body: JSON.stringify({
                                          nomeOfc: this.state.nomeOfc,
                                          Resp: this.state.Resp,
                                          Edrc: this.state.Edrc,
                                          Cidade: this.state.Cidade,
                                          Telefone: this.state.Telefone,
                                          Cns: this.state.Cns,
                                        }),
                                      }
                                    ).then((response) => {
                                      if (response.status == 200) {
                                        response.json().then((data) => {
                                          toast.update(id, {
                                            render:
                                              "cartório adicionado com sucesso!",
                                            type: "success",
                                            isLoading: false,
                                            autoClose: 3000,
                                          });
                                          Modal.close();
                                          const newItem = {
                                            id: data.id,
                                            nome: this.state.nomeOfc,
                                            resp: this.state.Resp,
                                            edrc: this.state.Edrc,
                                            cidade: this.state.Cidade,
                                            telefone: this.state.Telefone,
                                            Cns: this.state.Cns,
                                          };
                                          console.log(this.state);
                                          this.setState((prevState) => ({
                                            data: {
                                              ...prevState.data,
                                              cartorio: [
                                                ...prevState.data.cartorio,
                                                newItem,
                                              ],
                                            },
                                          }));
                                        });
                                      }
                                    });
                                  }}
                                >
                                  <i class="fas fa-download"></i> Salvar{" "}
                                </button>
                              </div>
                            </>
                          ),
                        });
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faUniversity} /> Adicionar cartório{" "}
                    </button>
                  </div>
                  <div class="card-body">
                    <div></div>

                    {this.state.data.cartorio.length > 0 ? (
                      <>
                        <table class="ntbl table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Nome</th>

                              <th scope="col">Endereço</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.cartorio.map((item, index) => (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.nome}</td>

                                  <td>{item.edrc}</td>
                                  <td>
                                    <div class=" btn-group-sm">
                                      <button
                                        class="btn btn-primary"
                                        onClick={() => {
                                          console.log(item);
                                          this.setState({
                                            editCartorio: item,
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          color="#fff"
                                          icon={faPen}
                                        />
                                      </button>
                                      <button
                                        class="btn btn-danger"
                                        onClick={() => {
                                          Modal.create({
                                            titulo: "Tem certeza?",
                                            html: (
                                              <>
                                                <p>
                                                  Após a remoção, não será
                                                  possível recuperar o dado.
                                                </p>
                                                <div class="card-footer">
                                                  <button
                                                    type="submit"
                                                    style={{
                                                      background: "#dc3545",
                                                    }}
                                                    class="btn btn-danger float-right ml-2"
                                                    onClick={() => {
                                                      const id =
                                                        toast.loading(
                                                          "Carregando..."
                                                        );
                                                      fetch(
                                                        `${apiUrl}${window.location.pathname}/remover-cartorio/${item.id}`,
                                                        {
                                                          method: "DELETE",
                                                          credentials:
                                                            "include",
                                                          headers: {
                                                            "Content-Type":
                                                              "application/json",
                                                            Authorization:
                                                              "Bearer " +
                                                              Cookies.get(
                                                                "auth_token"
                                                              ),
                                                          },
                                                        }
                                                      ).then((response) => {
                                                        if (
                                                          response.status == 200
                                                        ) {
                                                          toast.update(id, {
                                                            render:
                                                              "Cartório removido com sucesso!",
                                                            type: "success",
                                                            isLoading: false,
                                                            autoClose: 3000,
                                                          });

                                                          this.setState(
                                                            (prevState) => ({
                                                              data: {
                                                                ...prevState.data,
                                                                cartorio:
                                                                  prevState.data.cartorio.filter(
                                                                    (i) =>
                                                                      i.id !==
                                                                      item.id
                                                                  ),
                                                              },
                                                            })
                                                          );
                                                          Modal.close();
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <i class="fas fa-download"></i>{" "}
                                                    Remover{" "}
                                                  </button>
                                                </div>
                                              </>
                                            ),
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>Não há dados cadastrados!</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <Link
                      className="btn btn-danger"
                      to="/tjsc/novo-solicitante"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Novo Solicitante
                    </Link>
                  </div>
                  <div class="card-body">
                    {this.state.data.slc.length > 0 ? (
                      <>
                        <table class="ntbl table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Nome</th>
                              <th>Documento</th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.slc.map((item, index) => (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.nome}</td>
                                  <td>{item.numeroDocumento}</td>

                                  <td>
                                    <div class=" btn-group-sm">
                                      <Link
                                        class="btn btn-primary"
                                        style={{
                                          borderTopRightRadius: 0,
                                          borderBottomRightRadius: 0,
                                        }}
                                        to={`/${
                                          window.location.pathname.split("/")[1]
                                        }/edit/solicitante/${item.token}`}
                                      >
                                        <FontAwesomeIcon
                                          color="#fff"
                                          icon={faPen}
                                        />
                                      </Link>
                                      <button
                                        className="btn btn-danger"
                                        style={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                        }}
                                        onClick={() => {
                                          this.setState({ DelSlc: item });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          color="#fff"
                                          icon={faTrash}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>Não há solicitantes cadastrados!</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header d-flex justify-content-between">
                    <div className="area-buscas">
                      <Link
                        class="btn btn-xs btn-primary-2"
                        style={{ background: "#dc3545", color: "#fff" }}
                        to={`${window.location.pathname}/create`}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Adicionar certidão
                      </Link>

                      <input
                        type="text"
                        placeholder="Pesquisar:"
                        style={{ marginLeft: 10 }}
                      />
                      <button>
                        <FontAwesomeIcon
                          color="#fff"
                          icon={faMagnifyingGlass}
                        />
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <p>Lista de certidão</p>
                        <div></div>
                        {this.state.data.certs.length > 0 ? (
                          <>
                            <table class="ntbl table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Nome na Certidão</th>
                                  <th scope="col">Tipo de Documento</th>
                                  <th>Selo Nacional</th>
                                  <th>Selo Estadual</th>
                                  <th>Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.certs.map((item, index) => (
                                  <>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item.titular}</td>
                                      <td>{item.tipo_ato}</td>
                                      <td>{item.selo_nacional}</td>
                                      <td>{item.token}</td>
                                      <td>
                                        <div class="btn-group btn-group-sm">
                                          <Link
                                            class="btn btn-success"
                                            to={`/${
                                              window.location.pathname.split(
                                                "/"
                                              )[1]
                                            }/preview/${item.token}`}
                                          >
                                            <FontAwesomeIcon
                                              color="#fff"
                                              icon={faEye}
                                            />
                                          </Link>
                                          <Link
                                            class="btn btn-info"
                                            to={`/${
                                              window.location.pathname.split(
                                                "/"
                                              )[1]
                                            }/edit/${item.token}`}
                                          >
                                            <FontAwesomeIcon
                                              color="#fff"
                                              icon={faPen}
                                            />
                                          </Link>
                                          <button
                                            class="btn btn-danger"
                                            onClick={() => {
                                              Modal.create({
                                                titulo: "Tem certeza?",
                                                html: (
                                                  <>
                                                    <p>
                                                      Após a remoção, não será
                                                      possível recuperar o dado.
                                                    </p>
                                                    <div class="card-footer">
                                                      <button
                                                        type="submit"
                                                        style={{
                                                          background: "#dc3545",
                                                        }}
                                                        class="btn btn-danger float-right ml-2"
                                                        onClick={() => {
                                                          const id =
                                                            toast.loading(
                                                              "Carregando..."
                                                            );
                                                          fetch(
                                                            `${apiUrl}${window.location.pathname}/remover-certificado/${item.token}`,
                                                            {
                                                              method: "DELETE",
                                                              credentials:
                                                                "include",
                                                              headers: {
                                                                "Content-Type":
                                                                  "application/json",
                                                                Authorization:
                                                                  "Bearer " +
                                                                  Cookies.get(
                                                                    "auth_token"
                                                                  ),
                                                              },
                                                            }
                                                          ).then((response) => {
                                                            if (
                                                              response.status ==
                                                              200
                                                            ) {
                                                              toast.update(id, {
                                                                render:
                                                                  "Certificado removido com sucesso!",
                                                                type: "success",
                                                                isLoading: false,
                                                                autoClose: 3000,
                                                              });

                                                              this.setState(
                                                                (
                                                                  prevState
                                                                ) => ({
                                                                  data: {
                                                                    ...prevState.data,
                                                                    certs:
                                                                      prevState.data.certs.filter(
                                                                        (i) =>
                                                                          i.id !==
                                                                          item.id
                                                                      ),
                                                                  },
                                                                })
                                                              );
                                                              Modal.close();
                                                            }
                                                          });
                                                        }}
                                                      >
                                                        <i class="fas fa-download"></i>{" "}
                                                        Remover{" "}
                                                      </button>
                                                    </div>
                                                  </>
                                                ),
                                              });
                                            }}
                                          >
                                            {" "}
                                            <FontAwesomeIcon icon={faTrash} />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>Não há dados cadastrados!</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div id="modal-root"></div>

        <StaticDialog
          isOpen={this.state.newSlc}
          title="Adicionar Solicitante"
          onAfterClose={(result) => {
            this.setState({ newSlc: false });
          }}
        >
          <div className="container">
            <form
              id="cadastroForm"
              onSubmit={(e) => {
                var dados = this.state.dados;
                e.preventDefault();
                console.log(dados);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Nome"
                    name="nome"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Data de Nascimento"
                    name="dataNascimento"
                    type="date" // Define o tipo como "date"
                    variant="outlined" // Pode ser "outlined", "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputLabelProps={{
                      shrink: true, // Garante que o label fique posicionado corretamente
                    }}
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.addCartorio).length > 0}
          title="Adicionar cartório"
          onAfterClose={(result) => {
            this.setState({ addCartorio: [] });
          }}
        >
          <div className="container">
            <div className="mt-3 mb-3">
              <TextField
                label="Nome do Ofício"
                value={this.state.dados.nomeOfc || ""}
                name="nomeOfc"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mt-3 mb-3">
              <TextField
                label="Oficial"
                name="ofc"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mt-3 mb-3">
              <TextField
                label="Responsavel Pela Assinatura"
                name="rpa"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mt-3 mb-3">
              <TextField
                label="Cargo do Responsavel"
                name="cdr"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mb-3">
              <TextField
                label="Endereço"
                value={this.state.dados.Edrc || ""}
                name="Edrc"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
            <div className="mb-3">
              <TextField
                label="Bairro"
                value={this.state.dados.bairro || ""}
                name="bairro"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <TextField
                  label="Cidade"
                  value={this.state.dados.cidade || ""}
                  name="cidade"
                  onChange={(e) => this.handleChange(e)}
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                  fullWidth // Ocupa toda a largura do contêiner
                />
              </div>

              <div className="col-md-6">
                <TextField
                  label="Estado"
                  value="/ SC"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                  disabled
                  fullWidth // Ocupa toda a largura do contêiner
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <TextField
                  label="ISS"
                  value={this.state.dados.frj || ""}
                  name="frj"
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados, // Mantém os outros campos do objeto `dados`
                        ["frj"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                  fullWidth // Ocupa toda a largura do contêiner
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="CNS"
                  value={this.state.dados.Cns || ""}
                  name="cns"
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados, // Mantém os outros campos do objeto `dados`
                        ["Cns"]: e.target.value, // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                  fullWidth // Ocupa toda a largura do contêiner
                />
              </div>
            </div>

            <div className="mb-3">
              <TextField
                label="Telefones"
                value={this.state.dados.Telefone || ""}
                name="Telefone"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
            <div className="mb-3">
              <TextField
                label="E-mail"
                value={this.state.dados.email || ""}
                name="email"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              class="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");

                var {
                  nomeOfc,
                  ofc,
                  rpa,
                  cdr,
                  Resp,
                  Edrc,
                  bairro,
                  Cidade,
                  frj,
                  Telefone,
                  email,
                  cns,
                } = this.state.dados;

                fetch(
                  `${apiUrl}${window.location.pathname}/adicionar-cartorio/`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc,
                      Edrc,
                      ofc,
                      rpa,
                      cdr,
                      cns,
                      Bairro: bairro,
                      Cidade: Cidade + "/ SC",
                      Cds: frj,
                      Telefone,
                      email,
                    }),
                  }
                ).then((response) => {
                  if (response.status == 200) {
                    response.json().then((data) => {
                      toast.success("Cartório adicionado com sucesso!");

                      const newItem = {
                        id: data.id,
                        nome: nomeOfc,
                        ofc: ofc,
                        rpa: rpa,
                        cdr: cdr,
                        resp: Resp,
                        edrc: Edrc,
                        bairro: bairro,
                        cidade: Cidade,
                        frj: frj,
                        cns,
                        telefone: Telefone,
                        email: email,
                      };

                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: [...prevState.data.cartorio, newItem],
                        },
                        addCartorio: [],
                      }));
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.editCartorio).length > 0}
          title="Editar cartório"
          onAfterClose={(result) => {
            this.setState({ editCartorio: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div className="mb-3">
              <TextField
                label="Nome do Ofício"
                value={this.state.editCartorio.nome || ""}
                name="nome"
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
            <div className="mt-3 mb-3">
              <TextField
                label="Oficial"
                name="ofc"
                value={this.state.editCartorio.ofc || ""}
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mt-3 mb-3">
              <TextField
                label="Responsavel Pela Assinatura"
                name="rpa"
                value={this.state.editCartorio.rpa || ""}
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mt-3 mb-3">
              <TextField
                label="Cargo do Responsavel"
                name="cdr"
                value={this.state.editCartorio.cdr || ""}
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mb-3">
              <TextField
                label="Endereço"
                value={this.state.editCartorio.edrc || ""}
                name="edrc"
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
            <div className="mb-3">
              <TextField
                label="Bairro"
                value={this.state.editCartorio.bairro || ""}
                name="bairro"
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Cidade / UF"
                    value={this.state.editCartorio.cidade || ""}
                    name="cidade"
                    onChange={(e) => this.handleEditChange(e)}
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                    fullWidth // Ocupa toda a largura do contêiner
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Estado"
                    value="/ SC"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                    disabled
                    fullWidth // Ocupa toda a largura do contêiner
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <TextField
                  label="ISS"
                  value={this.state.editCartorio.Cds || ""}
                  name="frj"
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      editCartorio: {
                        ...prevState.editCartorio, // Mantém os outros campos do objeto `dados`
                        ["Cds"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                  fullWidth // Ocupa toda a largura do contêiner
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="CNS"
                  value={this.state.editCartorio.Cns || ""}
                  name="cns"
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      editCartorio: {
                        ...prevState.editCartorio, // Mantém os outros campos do objeto `dados`
                        ["Cns"]: e.target.value, // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                  fullWidth // Ocupa toda a largura do contêiner
                />
              </div>
            </div>

            <div className="mb-3">
              <TextField
                label="Telefones"
                value={this.state.editCartorio.telefone || ""}
                name="telefone"
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
            <div className="mb-3">
              <TextField
                label="E-mail"
                value={this.state.editCartorio.email || ""}
                name="email"
                onChange={(e) => this.handleEditChange(e)}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                fullWidth // Ocupa toda a largura do contêiner
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              class="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");
                var {
                  id,
                  nome,
                  resp,
                  edrc,
                  bairro,
                  cidade,
                  estado,
                  Cds,
                  telefone,
                  email,
                  ofc,
                  rpa,
                  cdr,
                  Cns,
                } = this.state.editCartorio;
                fetch(
                  `${apiUrl}${window.location.pathname}/atualizar-cartorio/${id}`,
                  {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc: nome,
                      Resp: resp,
                      Edrc: edrc,
                      Bairro: bairro,
                      Cidade: cidade + "/ SC",
                      estado,
                      Cds,
                      Telefone: telefone,
                      email,
                      ofc,
                      rpa,
                      cdr,
                      Cns,
                    }),
                  }
                ).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status == 200) {
                    response.json().then((data) => {
                      toast.success("Cartorio editado com sucesso");

                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: prevState.data.cartorio.map((i) =>
                            i.id === id
                              ? {
                                  ...i,
                                  nome,
                                  resp,
                                  edrc,
                                  bairro,
                                  cidade,
                                  estado,
                                  telefone,
                                  Cds,
                                  email,
                                  ofc,
                                  rpa,
                                  cdr,
                                  Cns,
                                }
                              : i
                          ),
                        },
                        editCartorio: {},
                      }));
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.DelSlc).length > 0}
          title="Tem certeza?"
          onAfterClose={(result) => {
            this.setState({ DelSlc: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <p>Após a remoção, não será possível recuperar o dado.</p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={() => {
                console.log(this.state.DelSlc);
                fetch(
                  `${apiUrl}${window.location.pathname}/remover-solicitante/`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      token: this.state.DelSlc.token,
                    }),
                  }
                ).then((response) => {
                  if (response.status == 200) {
                    response.json().then((data) => {
                      toast.success("Cartorio removido com sucesso");
                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          slc: prevState.data.slc.filter(
                            (i) => i.id !== this.state.DelSlc.id
                          ),
                        },
                        DelSlc: {},
                      }));
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Remover
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default TjSc;
